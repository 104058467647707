import { Controller } from "@hotwired/stimulus";
import merge from "lodash.merge";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

// Connects to data-controller="chart"
export default class extends Controller {
  chart;

  static targets = ["canvas"];

  static values = {
    type: { type: String, default: "line" },
    data: Object,
    options: Object,
  };

  connect() {
    const element = this.hasCanvasTarget ? this.canvasTarget : this.element;

    this.chart = new Chart(element.getContext("2d"), {
      type: this.typeValue,
      data: this.dataValue,
      options: merge(this.defaultOptions, this.optionsValue),
    });
  }

  get defaultOptions() {
    return {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
      interaction: {
        mode: "index",
        intersect: false,
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    };
  }
}
