import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

// Connects to data-controller="tooltip"
export default class extends Controller {
  static targets = ["trigger", "content"];

  static values = {
    message: String,
    trigger: String,
    interactive: { type: Boolean, default: false },
    maxWidth: String,
  };

  initialize() {
    const target = this.hasTriggerTarget ? this.triggerTarget : this.element;

    const content = this.hasContentTarget
      ? this.contentTarget.innerHTML
      : this.messageValue;

    tippy(target, {
      content,
      allowHTML: this.hasContentTarget,
      trigger: this.triggerValue || undefined,
      interactive: this.interactiveValue,
      maxWidth: this.maxWidthValue || undefined,
    });
  }
}
