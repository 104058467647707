import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="tinymce"
export default class extends Controller {
  connect() {
    tinymce.init({
      target: this.element,
      license_key: "gpl",
      toolbar:
        "undo redo | blocks | bold italic underline link | align bullist numlist",
      toolbar_sticky: true,
      height: 700,
      plugins: "autolink link lists",
      menubar: false,
      statusbar: false,
      block_formats: "Header 1=h1;Header 2=h2;Paragraph=p;",
      editable_root: false,
      editable_class: "tiny-editable",
      content_style: `
        .tiny-editable {
          position: relative;
        }

        .tiny-editable:hover:not(:focus),
        .tiny-editable:focus {
          outline: 2px solid #b4d7ff;
          outline-offset: 4px;
        }

        h1 {
          font-size: 1.3rem;
        }

        h2 {
          font-size: 1.1rem;
        }

        p {
          font-size: 0.9rem;
        }
      `,
      mergetags_list: [
        {
          title: "Organization",
          menu: [
            { value: "organization.name" },
            { value: "organization.tax_id" },
            { value: "organization.address" },
          ],
        },
        {
          title: "Player",
          menu: [{ value: "player.name" }],
        },
        {
          title: "Camp",
          menu: [
            { value: "camp.name" },
            { value: "camp.start_date" },
            { value: "camp.end_date" },
            { value: "camp.location" },
          ],
        },
        {
          title: "Registration",
          menu: [{ value: "registration.amount_paid" }],
        },
      ],
    });
  }

  disconnect() {
    tinymce.remove();
  }
}
