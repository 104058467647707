// Load Vite env
// @ts-ignore
const env = import.meta.env;

// Hotwire / Turbo / Stimulus
import "@hotwired/turbo-rails";
import "../controllers";

// Alpine.js
import Alpine from "alpinejs";
import AlpineUI from "@alpinejs/ui";
import AlpineFocus from "@alpinejs/focus";
import AlpineCollapse from "@alpinejs/collapse";
import AlpineAnchor from "@alpinejs/anchor";

Alpine.plugin(AlpineFocus);
Alpine.plugin(AlpineCollapse);
Alpine.plugin(AlpineAnchor);
Alpine.plugin(AlpineUI);

Alpine.magic("clipboard", (el) => (text, animate = true) => {
  const originalTextContent = el.textContent;

  window.navigator.clipboard.writeText(text).then(() => {
    if (animate) {
      el.textContent = "Copied!";
      setTimeout(() => {
        el.textContent = originalTextContent;
      }, 2_000);
    }
  });
});

Alpine.start();
(window as any).Alpine = Alpine;

// LogRocket
import LogRocket from "logrocket";

if (env.PROD) {
  LogRocket.init("coerver-colorado/clutch");
}

const currentUser = (window as any).currentUser;
if (currentUser) {
  LogRocket.identify(currentUser.id, currentUser);
}
