import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['template', 'container']

  static values = {
    templateId: {type: String, default: 'NEW_ITEM'},
    recordSelector: {type: String, default: '[data-new-record]'}
  }

  connect() {
    console.log('nested-form-connected', this.templateIdValue);
  }

  add(e) {
    e.preventDefault()

    const content = this.templateTarget.innerHTML.replace(
      new RegExp(this.templateIdValue, 'g'),
      new Date().getTime().toString()
    )
    this.containerTarget.insertAdjacentHTML('beforeend', content)
  }

  remove(e) {
    e.preventDefault()

    const record = e.target.closest(this.recordSelectorValue)

    if (record.dataset.newRecord === 'true') {
      record.remove()
    } else {
      record.style.display = 'none'
      const destroyInput = record.querySelector("input[name*='_destroy']")
      destroyInput.value = '1'
    }
  }
}
